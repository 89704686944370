@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  scroll-behavior: smooth;
}

.cust_simple_drop_down {
  position: relative;
  max-height: 2.8439153439vw;
  color: #929292;
  font-family: Oswald;
  font-size: 0.9259259259vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 0.2645502646vw;
  border: 1px solid rgba(73, 203, 52, 0.12);
  background: #0e0e10;
}
@media screen and (max-width: 500px) {
  .cust_simple_drop_down {
    max-height: 11.4666666667vw;
    font-size: 3.7333333333vw;
    border-radius: 1.0666666667vw;
  }
}
.cust_simple_drop_down .inner_cust_simple_drop_down {
  padding: 0.5291005291vw 1.3227513228vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
}
@media screen and (max-width: 500px) {
  .cust_simple_drop_down .inner_cust_simple_drop_down {
    padding: 1.8666666667vw 5.3333333333vw;
  }
}
.cust_simple_drop_down .inner_cust_simple_drop_down .inner_cust_simple_drop_down_icons {
  display: flex;
}
.cust_simple_drop_down .inner_cust_simple_drop_down .inner_cust_simple_drop_down_icons svg {
  height: 1.917989418vw;
  width: 1.917989418vw;
}
@media screen and (max-width: 500px) {
  .cust_simple_drop_down .inner_cust_simple_drop_down .inner_cust_simple_drop_down_icons svg {
    height: 7.7333333333vw;
    width: 7.7333333333vw;
  }
}
.cust_simple_drop_down .inner_cust_simple_drop_down .inner_cust_simple_drop_down_icons.active_icons {
  transform: rotate(180deg);
}
.cust_simple_drop_down .inner_cust_simple_drop_down_list {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: 0.3968253968vw 0;
  padding: 0.7275132275vw 1.3227513228vw;
  justify-content: space-between;
  text-align: start;
  border-radius: 6px;
  border: 1px solid rgba(73, 203, 52, 0.12);
  position: absolute;
  background-color: #0e0e10;
  z-index: 1;
  left: 0;
  right: 0;
}
@media screen and (max-width: 500px) {
  .cust_simple_drop_down .inner_cust_simple_drop_down_list {
    gap: 1.6vw 0;
    padding: 2.9333333333vw 5.3333333333vw;
  }
}

.cust_inp {
  font-family: Oswald !important;
  color: #929292;
  font-size: 0.9259259259vw;
  font-style: normal;
  line-height: normal;
  font-weight: 300;
}
@media screen and (max-width: 500px) {
  .cust_inp {
    font-size: 3.7333333333vw;
  }
}
.cust_inp input,
.cust_inp textarea {
  color: #929292;
  font-family: Oswald !important;
  font-size: 0.9259259259vw;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.28px;
  background-color: transparent;
}
@media screen and (max-width: 500px) {
  .cust_inp input,
  .cust_inp textarea {
    font-size: 3.7333333333vw;
  }
}
.cust_inp input::-moz-placeholder {
  font-weight: 300;
}
.cust_inp input::placeholder {
  font-weight: 300;
}
.cust_inp.text_group > label {
  position: relative;
}
.cust_inp.text_group > label .inp_title {
  position: absolute;
  left: 8px;
  padding: 0 0.2645502646vw;
  transform: translateY(-50%);
}
@media screen and (max-width: 500px) {
  .cust_inp.text_group > label .inp_title {
    padding: 0 1.0666666667vw;
  }
}
.cust_inp.text_group > label input[type=text],
.cust_inp.text_group > label textarea,
.cust_inp.text_group > label input[type=number] {
  width: 100%;
  padding: 0.7275132275vw 1.5873015873vw;
  outline: 0;
  color: #929292;
  border-radius: 4px;
  border: 1px solid rgba(73, 203, 52, 0.12);
  background: #0e0e10;
}
@media screen and (max-width: 500px) {
  .cust_inp.text_group > label input[type=text],
  .cust_inp.text_group > label textarea,
  .cust_inp.text_group > label input[type=number] {
    padding: 2.9333333333vw 6.6666666667vw;
  }
}
.cust_inp.text_group > label input[type=text][error=true],
.cust_inp.text_group > label textarea[error=true],
.cust_inp.text_group > label input[type=number][error=true] {
  border: 1px solid red;
}

:root {
  --base: #101012;
  --green: #77bb12;
}

body {
  font-family: "Oswald", sans-serif;
  background: var(--base);
  color: #f9f9f9;
  overflow-x: hidden;
}
body .nav {
  height: 5.6216931217vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4.1666666667vw 0 6.6137566138vw;
  border-bottom: 1px solid #9d9d9d;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: var(--base);
  z-index: 100;
}
@media screen and (max-width: 500px) {
  body .nav {
    height: 17.0666666667vw;
    padding: 0 5.3333333333vw 0 5.3333333333vw;
  }
}
body .nav img {
  width: 7.0105820106vw;
  height: 3.5052910053vw;
}
@media screen and (max-width: 500px) {
  body .nav img {
    width: 17.0666666667vw;
    height: 8.5333333333vw;
  }
}
body .nav .btn {
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.5952380952vw 4.4312169312vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5291005291vw;
  background: #76be0a;
  box-shadow: 0px 0px 10px 0px rgba(6, 3, 13, 0.12);
  color: #f9f9f9;
  text-shadow: 0px 0px 9px rgba(7, 7, 7, 0.14);
  font-family: Oswald;
  font-size: 1.1904761905vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.8518518519vw;
}
@media screen and (max-width: 500px) {
  body .nav .btn {
    padding: 2.1333333333vw 4.9333333333vw;
    border-radius: 2.1333333333vw;
    font-size: 3.2vw;
    line-height: 4.2666666667vw;
  }
}
body .section1 {
  height: 62.3677248677vw;
  max-height: calc(100vh + 10px);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: end;
  justify-content: center;
}
body .section1 .bg {
  position: absolute;
  width: 170.9656084656vw;
  height: 176.9087301587vw;
  left: 15.833994709vw;
  top: -75.2645502646vw;
}
body .section1 .animated_collection1 {
  position: relative;
  width: 69.1415343915vw;
  height: 38.7777777778vw;
}
body .section1 .animated_collection1 .lunos_box {
  position: absolute;
  top: 1.3227513228vw;
  left: 7.4074074074vw;
  width: 4.7599867725vw;
  height: 4.4312169312vw;
  z-index: 10;
  border-radius: 5px;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
body .section1 .animated_collection1 .question {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 53.9682539683vw;
  height: 16.7566137566vw;
  z-index: 1;
  --var2: 1;
  --var3: 0;
  transform: scale(var(--var3));
  opacity: calc(1 - var(--var2));
  transition: all 0.1s linear;
}
body .section1 .animated_collection1 .magnified_bacteria {
  position: absolute;
  z-index: 10;
  top: 14.880952381vw;
  left: 46.4285714286vw;
  width: 39.2857142857vw;
  height: 26.1904761905vw;
  display: none;
}
body .section1 .animated_collection1 .shadow_screen {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(251, 251, 251, 0.26);
  --var1: 1;
  transition: all 0.1s linear;
  opacity: calc(1 - var(--var1));
  background: linear-gradient(180deg, #111112 -2.21%, rgba(6, 6, 6, 0) 27.78%, rgba(0, 0, 0, 0.08) 43.05%, rgba(3, 3, 4, 0) 65.64%, #0e0e10 103.35%);
}
body .section1 .animated_collection1 .fume_green {
  position: absolute;
  bottom: 0.1984126984vw;
  left: 0.3968253968vw;
  width: 21.7837301587vw;
  height: 25.2830687831vw;
  --var1: 1;
  transition: all 0.1s linear;
  transform: scale(calc(1 - var(--var1)));
  transform-origin: 100% 100%;
  opacity: calc(1 - var(--var1));
}
body .section1 .animated_collection1 .fume_green.part2 {
  transform: unset;
}
body .section1 .animated_collection1 .fume_sink {
  position: absolute;
  bottom: -0.2645502646vw;
  right: 7.2751322751vw;
  width: 12.630952381vw;
  height: auto;
  --var1: 1;
  transition: all 0.1s linear;
  transform: rotate(10deg) scale(calc(1 - var(--var1)));
  transform-origin: 100% 100%;
  opacity: calc(1 - var(--var1));
}
body .section1 .animated_collection1 .fume_sink.part2 {
  transform: unset;
}
body .section1 .animated_collection1 .stink_bomb,
body .section1 .animated_collection1 .toxzilla,
body .section1 .animated_collection1 .blare {
  position: absolute;
  width: 25.8697089947vw;
  height: auto;
  --var1: 1;
  transition: all 1s linear;
  transform: scale(calc(1 - var(--var1)));
  transform-origin: 50% 100%;
  opacity: calc(1 - var(--var1));
}
body .section1 .animated_collection1 .stink_bomb {
  top: 9.9206349206vw;
  left: 23.6772486772vw;
}
body .section1 .animated_collection1 .stink_bomb.pos2 {
  width: 17.4854497354vw;
  top: 2.9761904762vw;
  left: 19.3121693122vw;
  transition: all 1s linear;
  transform: rotate(-22.58deg);
}
body .section1 .animated_collection1 .stink_bomb.pos3 {
  width: 6.7533068783vw;
  top: -3.3068783069vw;
  left: 11.2433862434vw;
  transition: all 1s linear;
  transform: rotate(-160deg);
}
body .section1 .animated_collection1 .stink_bomb.final {
  width: 0vw;
  top: 1.9841269841vw;
  left: 9.9206349206vw;
  transition: all 1s linear;
  transform: rotate(-160deg);
}
body .section1 .animated_collection1 .toxzilla {
  width: 33.664021164vw;
  top: 9.7222222222vw;
  left: -9.2592592593vw;
  right: unset;
}
body .section1 .animated_collection1 .toxzilla.pos2 {
  width: 24.6031746032vw;
  top: 9.3253968254vw;
  left: -9.9206349206vw;
  transition: all 1s linear;
  transform: rotate(56deg);
}
body .section1 .animated_collection1 .toxzilla.pos3 {
  width: 4.1005291005vw;
  top: 27.7777777778vw;
  left: 15.873015873vw;
  transition: all 1s linear;
  transform: rotate(130deg);
}
body .section1 .animated_collection1 .toxzilla.final {
  width: 0vw;
  top: 33.0687830688vw;
  left: 15.873015873vw;
  transition: all 1s linear;
  transform: rotate(160deg);
}
body .section1 .animated_collection1 .blare {
  width: 22.0158730159vw;
  top: 17.1957671958vw;
  left: 46.2962962963vw;
}
body .section1 .animated_collection1 .blare.pos2 {
  width: 14.8148148148vw;
  top: 17.1957671958vw;
  left: 39.6825396825vw;
  transition: all 1s linear;
  transform: rotate(20deg);
}
body .section1 .animated_collection1 .blare.pos3 {
  width: 3.6375661376vw;
  top: 9.9206349206vw;
  left: 23.1481481481vw;
  transition: all 1s linear;
  transform: rotate(-70deg);
}
body .section1 .animated_collection1 .blare.final {
  width: 0vw;
  top: 3.3068783069vw;
  left: 9.9206349206vw;
  transition: all 1s linear;
  transform: rotate(160deg);
}
body .section1 .animated_collection1 .switch_to_lunos {
  position: absolute;
  width: 7.8703703704vw;
  height: 7.3412698413vw;
  top: 12.037037037vw;
  left: 6.1507936508vw;
  --var1: 1;
  transform: translateX(calc(-6.1507936508vw * var(--var1) * 2.5));
  opacity: calc(1 - var(--var1));
  transition: all 0.2s linear;
}
body .section1 .animated_collection1 .switch {
  width: 5.9523809524vw;
  height: 3.5052910053vw;
  border-radius: 1.9841269841vw;
  top: 7.6058201058vw;
  left: 6.746031746vw;
  transition: all 0.3s linear;
  --var1: 1;
  transform: translateX(calc(-6.1507936508vw * var(--var1) * 2.5));
  opacity: calc(1 - var(--var1));
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
body .section1 .animated_collection1 .switch .switch_animate {
  padding: 0.5291005291vw;
  height: calc(100% - 6px);
  width: calc(100% - 6px);
  border-radius: 1.7857142857vw;
  background: #393939;
  position: relative;
}
body .section1 .animated_collection1 .switch .switch_animate:before {
  z-index: -1;
  content: "";
  top: -50%;
  right: 0;
  left: -3px;
  margin: auto;
  position: absolute;
  aspect-ratio: 1;
  width: calc(100% + 6px);
  animation: rotating 2s linear infinite;
  background-image: conic-gradient(from 0deg, transparent, transparent, #76be0a, #76be0a);
}
@keyframes rotating {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
body .section1 .animated_collection1 .switch .switch_main {
  position: relative;
  height: 100%;
  width: 100%;
}
body .section1 .animated_collection1 .switch .switch_main .circle {
  position: absolute;
  height: 100%;
  aspect-ratio: 1;
  background: #fff;
  border-radius: 50%;
  top: 0;
  left: 0;
  transition: all 0.3s linear;
}
body .section1 .animated_collection1 .switch .switch_main .dot {
  position: absolute;
  height: 30%;
  aspect-ratio: 1;
  border: 1px solid #fff;
  border-radius: 50%;
  top: 20%;
  right: 20%;
}
body .section1 .animated_collection1 .switch .switch_main .minus {
  position: absolute;
  height: 0.5291005291vw;
  width: 1px;
  aspect-ratio: 1;
  background: #fff;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 25%;
}
body .section1 .animated_collection1 .switch.active {
  background: #00a110;
}
body .section1 .animated_collection1 .switch.active .circle {
  left: 100%;
  transform: translateX(-100%);
}
body .section1 .animated_collection1 .item_expander {
  position: absolute;
  position: absolute;
  width: 36.1772486772vw;
  height: 18.0555555556vw;
  top: 0.1984126984vw;
  left: 6.2830687831vw;
}
body .section1.section1mobile {
  min-height: 199.4666666667vw;
  max-height: calc(100vh + 10px);
  background: -webkit-repeating-linear-gradient();
}
body .section1.section1mobile .animated_collection1 {
  position: relative;
  margin: 0 0 30.9333333333vw 0;
  width: 89.3333333333vw;
  height: 106.4vw;
}
body .section1.section1mobile .animated_collection1 .lunos_box {
  position: absolute;
  top: 9.6vw;
  left: 3.4666666667vw;
  width: 11.2vw;
  height: 11.2vw;
  z-index: 10;
  border-radius: 5px;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
body .section1.section1mobile .animated_collection1 .question {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
  z-index: 1;
  --var1: 100;
  --var2: 1;
  --var3: 0;
  transform: translateY(calc(-95% + -1% * var(--var1)));
}
body .section1.section1mobile .animated_collection1 .lunos_image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
  z-index: 1;
  --var1: 100;
  --var2: 1;
  transform: translateY(calc(-95% + -1% * (100 - var(--var1))));
  opacity: calc(var(--var2));
  transition: all 1s linear;
}
body .section1.section1mobile .animated_collection1 .magnified_bacteria {
  position: absolute;
  z-index: 10;
  top: unset;
  left: unset;
  bottom: -7.2vw;
  right: -10.6666666667vw;
  width: 77.6853333333vw;
  height: 51.7893333333vw;
}
body .section1.section1mobile .animated_collection1 .shadow_screen {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(251, 251, 251, 0.26);
  --var1: 1;
  transition: all 0.1s linear;
  opacity: calc(1 - var(--var1));
  background: linear-gradient(180deg, #111112 -2.21%, rgba(6, 6, 6, 0) 27.78%, rgba(0, 0, 0, 0.08) 43.05%, rgba(3, 3, 4, 0) 65.64%, #0e0e10 103.35%);
}
body .section1.section1mobile .animated_collection1 .fume_green {
  position: absolute;
  bottom: 16vw;
  left: -8vw;
  width: 42.6666666667vw;
  height: 49.408vw;
  --var1: 1;
  transition: all 0.1s linear;
  transform: scale(calc(1 - var(--var1)));
  transform-origin: 100% 100%;
  opacity: calc(1 - var(--var1));
}
body .section1.section1mobile .animated_collection1 .fume_green.part2 {
  transform: unset;
}
body .section1.section1mobile .animated_collection1 .fume_sink {
  position: absolute;
  bottom: 8vw;
  left: 0vw;
  width: 56vw;
  height: auto;
  --var1: 1;
  transition: all 0.1s linear;
  transform: rotate(0deg) scale(calc(1 - var(--var1)));
  transform-origin: 0% 100%;
  opacity: calc(1 - var(--var1));
}
body .section1.section1mobile .animated_collection1 .fume_sink.part2 {
  transform: unset;
}
body .section1.section1mobile .animated_collection1 .stink_bomb,
body .section1.section1mobile .animated_collection1 .toxzilla,
body .section1.section1mobile .animated_collection1 .blare {
  position: absolute;
  width: 45.0666666667vw;
  height: auto;
  --var1: 1;
  transition: all 0.1s linear;
  transform: scale(calc(1 - var(--var1)));
  transform-origin: 50% 100%;
  opacity: calc(1 - var(--var1));
}
body .section1.section1mobile .animated_collection1 .stink_bomb {
  top: 46.1333333333vw;
  left: 37.3333333333vw;
}
body .section1.section1mobile .animated_collection1 .stink_bomb.pos2 {
  width: 32.7653333333vw;
  top: 26.6666666667vw;
  left: 34.6666666667vw;
  transition: all 1s linear;
  transform: rotate(-22.58deg);
  filter: blur(1px);
}
body .section1.section1mobile .animated_collection1 .stink_bomb.pos3 {
  width: 14.6666666667vw;
  top: 0vw;
  left: 13.3333333333vw;
  transition: all 1s linear;
  transform: rotate(-160deg);
}
body .section1.section1mobile .animated_collection1 .stink_bomb.final {
  width: 0vw;
  top: 13.3333333333vw;
  left: 8vw;
  transition: all 1s linear;
  transform: rotate(-160deg);
}
body .section1.section1mobile .animated_collection1 .toxzilla {
  width: 56.2109333333vw;
  top: 59.4666666667vw;
  left: -16.8vw;
  right: unset;
}
body .section1.section1mobile .animated_collection1 .toxzilla.pos2 {
  width: 38.392vw;
  top: 53.3333333333vw;
  left: calc_, Mcalc_mobile(-150);
  transition: all 1s linear;
  transform: rotate(56deg);
  filter: blur(1px);
}
body .section1.section1mobile .animated_collection1 .toxzilla.pos3 {
  width: 14.6666666667vw;
  top: 72vw;
  left: 13.3333333333vw;
  transition: all 1s linear;
  transform: rotate(90deg);
}
body .section1.section1mobile .animated_collection1 .toxzilla.final {
  width: 0vw;
  top: 82.6666666667vw;
  left: 26.6666666667vw;
  transition: all 1s linear;
  transform: rotate(90deg);
}
body .section1.section1mobile .animated_collection1 .blare {
  width: 36.8vw;
  top: 72vw;
  left: 59.2vw;
}
body .section1.section1mobile .animated_collection1 .blare.pos2 {
  width: 25.1696vw;
  top: 54.6666666667vw;
  left: 59.4666666667vw;
  transition: all 1s linear;
  transform: rotate(20deg);
  filter: blur(1px);
}
body .section1.section1mobile .animated_collection1 .blare.pos3 {
  width: 10.8538666667vw;
  top: 17.8666666667vw;
  left: 38.888vw;
  transition: all 1s linear;
  transform: rotate(-70deg);
}
body .section1.section1mobile .animated_collection1 .blare.final {
  width: 0vw;
  top: 6.6137566138vw;
  left: 9.9206349206vw;
  transition: all 1s linear;
  transform: rotate(160deg);
}
body .section1.section1mobile .animated_collection1 .switch_to_lunos {
  position: absolute;
  width: 16vw;
  height: 16.5333333333vw;
  top: 38.4vw;
  left: 1.3333333333vw;
  --var1: 1;
  transform: translateX(calc(-6.1507936508vw * var(--var1) * 2.5));
  opacity: calc(1 - var(--var1));
  transition: all 0.2s linear;
}
body .section1.section1mobile .animated_collection1 .switch {
  background: #393939;
  width: 10.6666666667vw;
  height: 6.1333333333vw;
  border-radius: 27px;
  position: absolute;
  top: 28vw;
  left: 4vw;
  transition: all 0.3s linear;
  --var1: 1;
  transform: translateX(calc(-24.8vw * var(--var1) * 2.5));
  opacity: calc(1 - var(--var1));
  cursor: pointer;
}
body .section1.section1mobile .animated_collection1 .switch .switch_animate {
  padding: 1.0666666667vw;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  border-radius: 7.2vw;
}
body .section1.section1mobile .animated_collection1 .switch .switch_main {
  position: relative;
  height: 100%;
  width: 100%;
}
body .section1.section1mobile .animated_collection1 .switch .switch_main .circle {
  position: absolute;
  height: 100%;
  aspect-ratio: 1;
  background: #fff;
  border-radius: 50%;
  top: 0;
  left: 0;
  transition: all 0.3s linear;
}
body .section1.section1mobile .animated_collection1 .switch .switch_main .dot {
  position: absolute;
  height: 30%;
  aspect-ratio: 1;
  border: 1px solid #fff;
  border-radius: 50%;
  top: 20%;
  right: 20%;
}
body .section1.section1mobile .animated_collection1 .switch .switch_main .minus {
  position: absolute;
  height: 0.5291005291vw;
  width: 1px;
  aspect-ratio: 1;
  background: #fff;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 25%;
}
body .section1.section1mobile .animated_collection1 .switch.active {
  background: #00a110;
}
body .section1.section1mobile .animated_collection1 .switch.active .circle {
  left: 100%;
  transform: translateX(-100%);
}
body .section1.section1mobile .animated_collection1 .item_expander {
  display: none;
  position: absolute;
  position: absolute;
  width: 36.1772486772vw;
  height: 18.0555555556vw;
  top: 0.1984126984vw;
  left: 6.2830687831vw;
}
body .section2 {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: end;
  justify-content: center;
}
body .section2 .holder {
  position: relative;
  width: 71.164021164vw;
  height: 100%;
  padding: 0 0 6.9444444444vw 0;
  text-align: center;
}
@media screen and (max-width: 500px) {
  body .section2 .holder {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
  }
}
body .section9 {
  position: relative;
  overflow: hidden;
}
body .section9 .lunos_bg {
  position: absolute;
  right: -4.1005291005vw;
  bottom: 1.6534391534vw;
  width: 55.8862433862vw;
  z-index: -1;
}
@media screen and (max-width: 500px) {
  body .section9 .lunos_bg {
    right: 0;
    bottom: -3.9682539683vw;
    width: 100%;
    z-index: -1;
  }
}
body .section9 .silvento {
  max-width: 41.7989417989vw;
  max-height: 31.2169312169vw;
  height: 100%;
}
@media screen and (max-width: 500px) {
  body .section9 .silvento {
    display: none;
    max-width: unset;
    max-height: unset;
  }
}
body .section9 .silvento.mobile {
  display: none;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 500px) {
  body .section9 .silvento.mobile {
    display: block;
  }
}
body .section9 .bg {
  position: absolute;
  width: 83.2096560847vw;
  height: 86.1018518519vw;
  top: -43.7169312169vw;
  left: -26.7857142857vw;
  z-index: -1;
}
@media screen and (max-width: 500px) {
  body .section9 .bg {
    width: 185.1851851852vw;
    height: auto;
    top: -105.8201058201vw;
    left: -66.1375661376vw;
  }
}
body .section9 .section_flex {
  display: flex;
  padding: 4.2989417989vw 6.4814814815vw 0 4.7619047619vw;
  gap: 3.4391534392vw;
  height: calc(100% - 9.9206349206vw);
}
@media screen and (max-width: 500px) {
  body .section9 .section_flex {
    height: unset;
    padding: 26.6666666667vw 5.3333333333vw 0 5.3333333333vw;
    gap: 5.3333333333vw;
    flex-direction: column;
  }
}
body .section9 .section_flex .img_holder {
  max-width: 41.7989417989vw;
  height: 100%;
  position: relative;
  margin: auto;
}
@media screen and (max-width: 500px) {
  body .section9 .section_flex .img_holder {
    width: 100%;
  }
}
body .section9 .section_flex .img_holder svg {
  position: absolute;
  height: 150%;
  width: auto;
  inset: 0;
  margin: auto;
}
body .section9 .section_flex .txt {
  width: calc(100% - 45.2380952381vw);
  color: #f9f9f9;
}
@media screen and (max-width: 500px) {
  body .section9 .section_flex .txt {
    width: 100%;
  }
}
body .section9 .section_flex .txt .header_section9 {
  width: 100%;
  max-height: 9.6560846561vw;
  height: 100%;
  margin: 0 0 2.1825396825vw 0;
}
body .section9 .section_flex .txt .header_section9 svg {
  width: 100%;
  height: 100%;
}
body .section9 .section_flex .txt .header_section9_desc {
  width: 100%;
  max-height: 10.5158730159vw;
  height: 100%;
}
body .section9 .section_flex .txt .header_section9_desc svg {
  width: 100%;
  height: 100%;
}
body .section9 .section_flex .txt .header {
  font-size: 4.1005291005vw;
  font-style: normal;
  font-weight: 400;
  line-height: 5.5555555556vw;
  margin: 0 0 2.1825396825vw 0;
}
@media screen and (max-width: 500px) {
  body .section9 .section_flex .txt .header {
    font-size: 11.2vw;
    line-height: 13.8666666667vw;
    margin: 0 0 5.3333333333vw 0;
  }
}
body .section9 .section_flex .txt .sub_txt {
  color: #fbfbfb;
  font-family: Lato;
  font-size: 1.5873015873vw;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  opacity: 0.6;
  padding: 0 1.8518518519vw 0 0;
}
@media screen and (max-width: 500px) {
  body .section9 .section_flex .txt .sub_txt {
    font-size: 4.8vw;
    padding: 0 0 0 0;
    margin: 0 0 5.3333333333vw 0;
  }
}
body .section9 .btn {
  margin: 4.9603174603vw auto 6.6137566138vw auto;
  display: flex;
  width: 27.5132275132vw;
  padding: 0.9920634921vw 5.291005291vw;
  justify-content: center;
  align-items: center;
  border-radius: 0.5291005291vw;
  background: #77be0b;
  box-shadow: 0px 0px 10px 0px rgba(6, 3, 13, 0.12);
}
@media screen and (max-width: 500px) {
  body .section9 .btn {
    width: calc(100% - 10.6666666667vw);
    padding: 4vw 0;
    border-radius: 2.1333333333vw;
  }
}
body .section10 {
  padding: 2.6455026455vw 0 0 0;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 500px) {
  body .section10 {
    padding: 26.6666666667vw 0 13.3333333333vw 0;
  }
}
body .section10 .bg {
  position: absolute;
  top: 3.1084656085vw;
  left: 31.4384920635vw;
  width: 84.2764550265vw;
  height: 80.5396825397vw;
}
body .section10 .header {
  color: #f9f9f9;
  text-align: center;
  font-family: Oswald;
  font-style: normal;
  font-weight: 400;
  font-size: 4.7619047619vw;
  line-height: 5.5555555556vw;
  font-size: 3.9682539683vw;
  line-height: 4.2328042328vw;
  width: 44.4444444444vw;
  margin: 0 auto;
}
@media screen and (max-width: 500px) {
  body .section10 .header {
    font-size: 11.2vw;
    line-height: 13.8666666667vw;
    width: calc(100% - 10.6666666667vw);
  }
}
body .section10 .header2 {
  width: 44.4444444444vw;
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-height: 900px) {
  body .section10 .header2 {
    text-align: center;
    height: 16.8vh;
  }
}
body .section10 .header2 svg {
  height: 100%;
}
body .section10 .section_flex {
  padding: 3.3068783069vw 2.0502645503vw 3.3068783069vw 4.5634920635vw;
  display: flex;
  height: 100%;
  gap: 0 1.3227513228vw;
}
@media screen and (max-width: 500px) {
  body .section10 .section_flex {
    flex-direction: column;
    padding: 5.3333333333vw 5.3333333333vw 0 5.3333333333vw;
  }
}
body .section10 .section_flex .video_flex {
  width: 40.2116402116vw;
}
@media screen and (max-width: 500px) {
  body .section10 .section_flex .video_flex {
    width: 100%;
  }
}
body .section10 .section_flex .video_flex iframe,
body .section10 .section_flex .video_flex video {
  aspect-ratio: 560/315;
  width: 100%;
  max-height: calc(100% - 13.2275132275vw);
}
body .section10 .section_flex .btn {
  color: #f9f9f9;
  text-align: center;
  text-shadow: 0px 0px 0.5952380952vw rgba(7, 7, 7, 0.14);
  font-family: Oswald;
  font-size: 1.3227513228vw;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.0264550265vw;
  display: flex;
  width: 25.462962963vw;
  padding: 0.9920634921vw 5.291005291vw;
  justify-content: center;
  align-items: center;
  border-radius: 0.5291005291vw;
  background: #77be0b;
  box-shadow: 0px 0px 0.6613756614vw 0px rgba(6, 3, 13, 0.12);
  margin: 2.6455026455vw auto 0 auto;
}
@media screen and (max-width: 500px) {
  body .section10 .section_flex .btn {
    display: none;
  }
}
body .section10 .section_flex .btn.mobile {
  display: none;
  font-size: 4.8vw;
  letter-spacing: 0.096vw;
  padding: 4vw 0;
  text-align: center;
  border-radius: 2.1333333333vw;
  width: 100%;
}
@media screen and (max-width: 500px) {
  body .section10 .section_flex .btn.mobile {
    display: block;
  }
}
body .section10 .section_flex .clicker_group {
  width: calc(100% - 40.2116402116vw);
  height: 100%;
}
@media screen and (max-width: 500px) {
  body .section10 .section_flex .clicker_group {
    width: 100%;
    height: unset;
    padding: 0 0 9.8666666667vw 0;
  }
}
body .clicker_animator {
  height: 100%;
  width: 100%;
  display: flex;
}
body .clicker_animator .collection_slider {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 1.8518518519vw;
  height: 27.4470899471vw;
}
@media screen and (max-width: 500px) {
  body .clicker_animator .collection_slider {
    height: 83.7333333333vw;
  }
}
body .clicker_animator .collection_slider > div {
  gap: 2.6455026455vw;
}
body .clicker_animator .collection_slider > div img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
}
body .clicker_animator .collection_slider > div.collection_holder {
  width: 100%;
}
body .clicker_animator .collection_slider > div.collection_holder .collection_main {
  height: 100%;
  width: 100%;
  position: relative;
}
body .clicker_animator .collection_slider > div.collection_holder .collection_main > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.2671957672vw;
  height: 8.2671957672vw;
  margin: 0 0 1.3227513228vw 0;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s linear;
}
@media screen and (max-width: 500px) {
  body .clicker_animator .collection_slider > div.collection_holder .collection_main > div {
    width: 23.3333333333vw;
    height: 23.3333333333vw;
  }
}
body .clicker_animator .collection_slider > div.collection_holder .collection_main > div.pos1 {
  width: 38.4920634921vw;
  height: 100%;
  right: 9.5899470899vw;
}
@media screen and (max-width: 500px) {
  body .clicker_animator .collection_slider > div.collection_holder .collection_main > div.pos1 {
    height: 80%;
    width: 68.2666666667vw;
  }
}
body .clicker_animator .collection_slider > div.collection_holder .collection_main > div.pos2 {
  top: 25%;
  transform: translateY(-50%);
}
@media screen and (max-width: 500px) {
  body .clicker_animator .collection_slider > div.collection_holder .collection_main > div.pos2 {
    top: 100%;
    right: 75%;
    transform: translateY(-100%) translateX(50%);
  }
}
body .clicker_animator .collection_slider > div.collection_holder .collection_main > div.pos3 {
  top: 75%;
  transform: translateY(-50%);
}
@media screen and (max-width: 500px) {
  body .clicker_animator .collection_slider > div.collection_holder .collection_main > div.pos3 {
    top: 100%;
    right: 25%;
    transform: translateY(-100%) translateX(50%);
  }
}
body .section11 {
  position: relative;
  overflow: hidden;
}
body .section11 .bg {
  position: absolute;
  width: 42.1461640212vw;
  height: 31.7883597884vw;
  width: 30.6878306878vw;
  height: 23.3465608466vw;
  top: 0;
  left: 0;
}
body .section11 .bg1 {
  position: absolute;
  width: 62.6322751323vw;
  height: 65.6084656085vw;
  bottom: -19.0476190476vw;
  right: -12.6322751323vw;
}

#root {
  overflow: hidden;
}

.App {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}
@media screen and (max-width: 500px) {
  .App {
    scroll-snap-type: unset;
    height: unset;
  }
}
.App .section_snap {
  scroll-snap-align: start;
  height: 100vh;
  padding: 5.6216931217vw 0 0 0;
}
.App .section_snap > div {
  height: 100% !important;
}
.App .section_snap .section11 {
  background-image: url("https://imgur.com/7qpqQol.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 500px) {
  .App .section_snap {
    scroll-snap-align: unset;
    scroll-margin: unset;
    height: unset;
  }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.section3_main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 3.8068783069vw);
}
.section3_main .section3_main_inner1 {
  height: 30%;
}
@media screen and (max-width: 500px) {
  .section3_main .section3_main_inner1 {
    height: unset;
  }
}
.section3_main .section3_main_inner1 .detailer {
  height: 100%;
}
.section3_main .detailer_main {
  height: calc(70% - 2.6455026455vw);
}
@media screen and (max-width: 500px) {
  .section3_main .detailer_main {
    height: unset;
  }
}
@media screen and (max-width: 500px) {
  .section3_main {
    height: unset;
  }
}

.section4_main,
.section5_main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 3.8068783069vw);
  padding: 0 0 1.3227513228vw 0;
}
.section4_main .section4_main_inner1,
.section4_main .section5_main_inner1,
.section5_main .section4_main_inner1,
.section5_main .section5_main_inner1 {
  height: 30%;
}
.section4_main .section4_main_inner1 .detailer,
.section4_main .section5_main_inner1 .detailer,
.section5_main .section4_main_inner1 .detailer,
.section5_main .section5_main_inner1 .detailer {
  height: 100%;
}
.section4_main .detailer_main,
.section5_main .detailer_main {
  height: 70%;
}
@media screen and (max-width: 500px) {
  .section4_main .detailer_main,
  .section5_main .detailer_main {
    height: unset;
  }
}
@media screen and (max-width: 500px) {
  .section4_main,
  .section5_main {
    height: unset;
  }
}

.detailer {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  gap: 7.8703703704vw;
  position: relative;
}
@media screen and (max-width: 500px) {
  .detailer {
    width: 89.3333333333vw;
    gap: 9.6vw;
    flex-direction: column;
  }
}
.detailer .bacteria_illustration {
  height: 100%;
  transform: translateX(-300%);
  transition: all, 0.4s linear;
}
.detailer .bacteria_illustration img {
  height: 100%;
}
@media screen and (max-width: 500px) {
  .detailer .bacteria_illustration {
    width: 47.6vw;
    height: 54.4vw;
  }
}
.detailer .floater_shade {
  position: absolute;
  top: -11.4417989418vw;
  right: -2.1501322751vw;
  width: 33.8776455026vw;
  height: 36.7162698413vw;
  z-index: -1;
}
@media screen and (max-width: 500px) {
  .detailer .floater_shade {
    top: 47.4666666667vw;
    top: unset;
    bottom: -50%;
    right: -60%;
    width: 133.3333333333vw;
    height: auto;
  }
}
.detailer .floater_lumos {
  position: absolute;
  bottom: -1.9841269841vw;
  left: -21.2301587302vw;
  width: 117.5264550265vw;
  height: 10.3174603175vw;
  z-index: -1;
}
@media screen and (max-width: 500px) {
  .detailer .floater_lumos {
    width: 100vw;
    height: auto;
    left: -5.3333333333vw;
    top: 46.6666666667vw;
    bottom: unset;
  }
}
.detailer .text_box {
  width: 50%;
  transform: translateX(300%);
  transition: all 0.4s linear;
}
.detailer .text_box svg {
  width: 100% !important;
}
@media screen and (max-width: 500px) {
  .detailer .text_box svg {
    height: 100% !important;
  }
}
@media screen and (max-width: 500px) {
  .detailer .text_box {
    margin: 0 0 8vw 0;
    width: 100%;
  }
}
.detailer .text_box span {
  color: var(--green);
}
.detailer.reverse {
  flex-direction: row-reverse;
}
.detailer.reverse .bacteria_illustration {
  transform: translateX(300%);
}
.detailer.reverse .text_box {
  transform: translateX(-300%);
}
@media screen and (max-width: 500px) {
  .detailer.reverse {
    flex-direction: column;
  }
}
.detailer[active=true] .bacteria_illustration {
  transform: translateX(0);
}
.detailer[active=true] .text_box {
  transform: translateX(0);
}

.detailer_main {
  z-index: 10;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  align-items: center;
  text-align: left;
  display: flex;
  gap: 7.208994709vw;
  width: 100%;
  padding: 2.8439153439vw 7.671957672vw 3.3068783069vw 6.4814814815vw;
  position: relative;
}
@media screen and (max-width: 500px) {
  .detailer_main {
    padding: 16vw 0 16vw 0;
    gap: 5.3333333333vw;
    flex-direction: column;
  }
}
.detailer_main .smoke_bg {
  position: absolute;
  width: 57.5396825397vw;
  height: 19.4444444444vw;
  flexshrink: 0;
  top: 0;
  left: -1.917989418vw;
  background: url(https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/70Ylota.png) no-repeat;
  background-size: 100%;
}
@media screen and (max-width: 500px) {
  .detailer_main .smoke_bg {
    width: 168.5333333333vw;
    height: 56.8vw;
    top: 0;
    left: -49.3333333333vw;
  }
}
.detailer_main .img_box {
  overflow: hidden;
  width: 38.8875661376vw;
}
.detailer_main .img_box .inner_img_box {
  width: 200%;
  display: flex;
  padding: 0 0 0 1.6534391534vw;
  height: 23.1481481481vw;
  transition: all 0.3s linear;
}
@media screen and (max-width: 500px) {
  .detailer_main .img_box .inner_img_box {
    height: 100%;
  }
}
.detailer_main .img_box .inner_img_box .main_img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.3s linear;
}
@media screen and (max-width: 500px) {
  .detailer_main .img_box .inner_img_box .main_img {
    padding: 0 2.6666666667vw;
  }
}
.detailer_main .img_box .inner_img_box .main_img img {
  max-width: 100%;
  max-height: 100%;
}
.detailer_main .img_box .inner_img_box.active {
  transform: translateX(-50%);
}
.detailer_main .img_box .btn_controll {
  cursor: pointer !important;
  position: relative;
  z-index: 100;
  display: flex;
  margin: 2.380952381vw 0 0 0;
}
@media screen and (max-width: 500px) {
  .detailer_main .img_box .btn_controll {
    margin: 0 5.3333333333vw 1.3333333333vw 0;
  }
}
.detailer_main .img_box .btn_controll .main_img_svg {
  height: 1.455026455vw !important;
  width: 2.7777777778vw !important;
  transition: width 0.3s linear;
}
@media screen and (max-width: 500px) {
  .detailer_main .img_box .btn_controll .main_img_svg {
    height: 5.8666666667vw !important;
    width: 11.2vw !important;
  }
}
.detailer_main .img_box .btn_controll .main_img_svg g g path {
  fill: #757575;
  transition: fill 0.3s linear;
}
.detailer_main .img_box .btn_controll .main_img_svg.active_svg {
  height: 1.455026455vw !important;
  width: 4.4973544974vw !important;
}
@media screen and (max-width: 500px) {
  .detailer_main .img_box .btn_controll .main_img_svg.active_svg {
    height: 5.8666666667vw !important;
    width: 18.1333333333vw !important;
  }
}
.detailer_main .img_box .btn_controll .main_img_svg.active_svg g g path {
  fill: #f7fff6;
}
@media screen and (max-width: 500px) {
  .detailer_main .img_box {
    width: 100%;
  }
}
.detailer_main .floater_img {
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 0.3s linear;
}
.detailer_main .floater_img.floater_img_active {
  transform: translateX(100%);
}
.detailer_main .floater_img.floater_img_active2 {
  transform: translateX(-100%);
  opacity: 0;
}
@media screen and (max-width: 500px) {
  .detailer_main .floater_img {
    bottom: 30.6666666667vw;
  }
}
.detailer_main .floater_img img {
  width: 31.6798941799vw;
}
@media screen and (max-width: 500px) {
  .detailer_main .floater_img img {
    width: 53.3333333333vw;
  }
}
.detailer_main.reverse {
  background-image: url(https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/qArMONx.png);
  flex-direction: row-reverse;
}
@media screen and (max-width: 500px) {
  .detailer_main.reverse {
    flex-direction: column;
  }
}
.detailer_main.reverse .floater_img {
  right: unset;
  left: 0;
}
.detailer_main.reverse .smoke_bg {
  top: 0;
  left: unset;
  right: -1.917989418vw;
  background: url(https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/70Ylota.png) no-repeat;
  transform: rotateY(180deg);
}
@media screen and (max-width: 500px) {
  .detailer_main.reverse .smoke_bg {
    right: -80vw;
  }
}
.detailer_main .txt {
  width: calc(100% - 38.8875661376vw - 7.208994709vw);
  display: flex;
  flex-direction: column;
  gap: 2.5132275132vw;
}
@media screen and (max-width: 500px) {
  .detailer_main .txt {
    gap: 5.3333333333vw;
    width: 94.6666666667vw;
    padding: 0 5.3333333333vw 0 5.3333333333vw;
  }
}
.detailer_main .txt .title {
  font-size: 3.9682539683vw;
  font-style: normal;
  font-weight: 400;
  line-height: 4.6296296296vw;
}
@media screen and (max-width: 500px) {
  .detailer_main .txt .title {
    font-size: 11.2vw;
    line-height: 13.8666666667vw;
  }
}
.detailer_main .txt .subtxt {
  opacity: 0.6;
  font-family: Lato;
  font-size: 1.0582010582vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4109347443vw;
}
@media screen and (max-width: 500px) {
  .detailer_main .txt .subtxt {
    font-size: 4.8vw;
    line-height: 7.4666666667vw;
  }
}
.detailer_main .btn {
  background: #77bb12;
  box-shadow: 0px 0px 10px 0px rgba(6, 3, 13, 0.12);
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.9920634921vw 5.291005291vw;
  justify-content: center;
  border-radius: 0.5291005291vw;
  align-items: center;
  text-shadow: 0px 0px 9px rgba(7, 7, 7, 0.14);
  font-family: Oswald;
  font-size: 1.3227513228vw;
  font-style: normal;
  line-height: 1.8518518519vw;
  letter-spacing: 0.0264550265vw;
  z-index: 1;
}
@media screen and (max-width: 500px) {
  .detailer_main .btn {
    display: none;
  }
}
.detailer_main .btn.btn_mobile {
  display: none;
  text-align: center;
  width: calc(100% - 10.6666666667vw);
  padding: 4vw 0;
  border-radius: calc_mobile 8;
  font-size: 4.8vw;
  line-height: 7.4666666667vw;
  letter-spacing: 0.096vw;
}
@media screen and (max-width: 500px) {
  .detailer_main .btn.btn_mobile {
    display: block;
  }
}

.section4 {
  margin: 2.6455026455vw 0 0 0;
}

.section6 {
  margin: 2.6455026455vw 0 2.6455026455vw 0;
}

.section8 {
  margin: 2.6455026455vw 0 2.6455026455vw 0;
}

.connect_now {
  padding: 4.6296296296vw 7.671957672vw 2.6455026455vw 6.4814814815vw;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media screen and (max-width: 500px) {
  .connect_now {
    padding: 26.6666666667vw 5.3333333333vw 16vw 5.3333333333vw;
  }
}
.connect_now .bg {
  position: absolute;
  width: 89.7486772487vw !important;
  height: 44.5767195767vw !important;
  top: -3.3068783069vw !important;
  left: -29.7619047619vw !important;
  transform: rotatez(17.4deg) !important;
}
@media screen and (max-width: 500px) {
  .connect_now .bg {
    width: 173.3333333333vw !important;
    height: auto !important;
    top: 21.3333333333vw !important;
    left: -60vw !important;
    transform: rotatez(17.4deg) !important;
  }
}
.connect_now .bg1 {
  position: absolute;
  width: 80.9424603175vw !important;
  height: 68.0145502646vw !important;
  bottom: -43.1878306878vw !important;
  right: -36.1772486772vw !important;
}
@media screen and (max-width: 500px) {
  .connect_now .bg1 {
    width: 160vw !important;
    height: auto !important;
    bottom: -61.3333333333vw !important;
    right: -61.3333333333vw !important;
  }
}
.connect_now .inner_connect_now {
  display: flex;
  gap: 0 1.9841269841vw;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_now {
    flex-direction: column;
  }
}
.connect_now .inner_connect_now .connect_now_title {
  color: #f8f8f8;
  font-family: Oswald;
  font-size: 4.2328042328vw;
  font-style: normal;
  font-weight: 500;
  line-height: 5.6878306878vw;
  text-align: start;
  width: 46.2962962963vw;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_now .connect_now_title {
    width: 100%;
    font-size: 8.5333333333vw;
    line-height: 9.6vw;
  }
}
.connect_now .inner_connect_now .connect_now_title img {
  height: 5.6878306878vw;
  width: 29.6957671958vw;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_now .connect_now_title img {
    width: 78.1333333333vw;
    height: auto;
  }
}
.connect_now .inner_connect_now .connect_now_input_list {
  display: flex;
  gap: 1.5211640212vw 2.0502645503vw;
  margin: 0 0 1.5211640212vw 0;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_now .connect_now_input_list {
    width: 100%;
    flex-wrap: wrap;
    gap: 5.3333333333vw;
    margin: 5.3333333333vw 0 0 0;
  }
}
.connect_now .inner_connect_now .connect_now_input_list .connect_now_input_left {
  width: 17.6587301587vw;
  height: 2.8439153439vw;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_now .connect_now_input_list .connect_now_input_left {
    width: 100%;
    height: 11.4666666667vw;
  }
}
.connect_now .inner_connect_now .connect_now_input_list .connect_now_input_right {
  width: 22.3544973545vw;
  height: 2.8439153439vw;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_now .connect_now_input_list .connect_now_input_right {
    width: 100%;
    height: 11.4666666667vw;
  }
}
.connect_now .inner_connect_now .connect_now_input_list_box {
  border-radius: 0.5291005291vw;
  background: #77be0b;
  box-shadow: 0px 0px 10px 0px rgba(6, 3, 13, 0.12);
  padding: 0.9920634921vw 5.291005291vw;
  color: #f9f9f9;
  font-size: 1.3227513228vw;
  line-height: 155%;
  text-align: center;
  font-weight: 500;
  width: 17.6587301587vw;
  margin: 3.9682539683vw 0 0 0;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_now .connect_now_input_list_box {
    width: 100%;
    border-radius: 2.1333333333vw;
    margin: 9.6vw 0 0 0;
    padding: 4vw 0;
    font-size: 4.8vw;
  }
}
.connect_now .inner_connect_brands {
  display: flex;
  align-items: center;
  margin: auto 0 0 0;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_brands {
    flex-wrap: wrap;
    margin: 8vw 0 0 0;
  }
}
.connect_now .inner_connect_brands .inner_connect_brands_box1 img {
  width: 7.0105820106vw;
  height: 3.5052910053vw;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_brands .inner_connect_brands_box1 img {
    width: 23.7333333333vw;
    height: 11.7333333333vw;
  }
}
.connect_now .inner_connect_brands .inner_connect_brands_box2 {
  margin: 0 0 0 25.5952380952vw;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_brands .inner_connect_brands_box2 {
    margin: 17.8666666667vw auto 0 auto;
    order: 3;
  }
}
.connect_now .inner_connect_brands .inner_connect_brands_box2 svg {
  width: 18.8492063492vw;
  height: 1.5873015873vw;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_brands .inner_connect_brands_box2 svg {
    width: 65.0666666667vw;
    height: 7.4666666667vw;
  }
}
.connect_now .inner_connect_brands .inner_connect_brands_box3 {
  margin: 0 0 0 18.253968254vw;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_brands .inner_connect_brands_box3 {
    margin: 0 0 0 auto;
  }
}
.connect_now .inner_connect_brands .inner_connect_brands_box3 img {
  width: 3.9682539683vw;
  height: auto;
}
@media screen and (max-width: 500px) {
  .connect_now .inner_connect_brands .inner_connect_brands_box3 img {
    width: 18.6666666667vw;
    height: auto;
  }
}

.modal {
  position: fixed;
  height: 100vh !important;
  width: 100vw;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s linear, opacity 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(174, 174, 174, 0.4666666667);
}
.modal.active_modal {
  opacity: 1;
  transform: scale(1);
}
.modal svg {
  width: auto;
  height: 100%;
}

.f-green {
  color: var(--green);
}

.cp,
.btn {
  cursor: pointer;
}
.cp a,
.btn a {
  text-decoration: none;
  color: inherit;
}

.thankyou {
  height: 100vh;
  width: 100vw;
  background-image: url("https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/MTPL/thankyoupage/Frame%201437254147.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thankyou .img1 {
  width: 60vw;
  height: 30vh;
}